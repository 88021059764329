Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.fetchMyLibaray = 'bx_block_chatbot4/outreach_history?favourited=true&recents=true&search=';
exports.getMyWorkflowsApiEndpoint = "bx_block_catalogue/campaigns/saved?recents=true&q="
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End