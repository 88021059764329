Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfdataintegrationviapromptengineering4";
exports.labelBodyText = "cfdataintegrationviapromptengineering4 Body";
exports.btnExampleTitle = "CLICK ME";
exports.deleteApiMethod = "DELETE";

exports.formCreationEndpoint = '/bx_block_automaticformcreation/form_creations';
exports.createCampainEndpoint = 'bx_block_cfdataintegrationviapromptengineering4/params';
exports.campaignSubmissionMethod = 'POST';
exports.campaignSubmissionEndpoint = 'bx_block_catalogue/campaigns';

exports.formSubmissionEndpoint = '/bx_block_cfdataintegrationviapromptengineering4/params';
exports.formSubmissionMethod = 'POST';
exports.apiEndPointCreateMail = "bx_builder_chain/generate_prompt_for_email"
exports.fetchCompanyInfoEndpoint = '/bx_builder_chain/fetch_company_info';
exports.getUserFileEndPoint = '/get_target_audience_files';
exports.httpPostMethod = "POST";
exports.webScappingAPI = 'bx_block_cfdataintegrationviapromptengineering4/params/create_param_for_document_and_webscraped_info';
exports.fetchMyLibaray = 'bx_block_chatbot4/outreach_history?favourited=true&search=';
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.httpPatchMethod = "PATCH";
exports.deleteMyLibraryApiEndpoint = "/bx_block_chatbot4/destroy_data/";
exports.removeMyLibraryApiEndpoint = "/bx_block_cfdataintegrationviapromptengineering4/generated_outputs/";
exports.updateChatHistoryMyLibraryApiEndpoint = "bx_block_chatbot4/update_copilot_name_intelligence?";
exports.campaignDetailAPI = '/bx_block_catalogue/campaigns/fetch_campaign_from_param_group';
exports.apiEndPointDeleteAllHistory = (selectedId,selectedSubField,type) => `bx_block_catalogue/campaigns/bulk_remove?${selectedId}&${selectedSubField}&${type}`
// Customizable Area End