Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "settings2";
exports.labelBodyText = "settings2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.currentMailLabel = "Current email";
exports.editButton = "Edit";
exports.currentPasswordLabel = "Current password";
exports.privacyPolicy = "Privacy Policy";
exports.termsCondition = "Terms & Conditions";
exports.deleteAccount = "Delete account";
exports.cancelButton = "Cancel";
exports.saveButton = "Save";
exports.areYouSure = "Are you sure you want to permanently";
exports.delete = "delete";
exports.yourAccount = "your account?";
exports.dataLose = "You'll lose all your stored company intel, your library and workflows, assets, and your copilot knowledge on your company.";
exports.keepData = "No, keep data";
exports.yesDelete = "Yes, delete";
exports.changeEmail = "Change email";
exports.newMailLabel = "New email";
exports.justSent = "We just sent you a";
exports.temporaryCode = "temporary signup code.";
exports.pleaseCheck = "Please check your";
exports.inbox = "inbox";
exports.pastCode = "and paste the sign up code below.";
exports.verificationCodeLabel = "Verification code";
exports.codePlaceholder = "Paste code here";
exports.submitButton = "Submit";
exports.didnotRecieve = "Didn’t receive code?";
exports.resend = "Resend";
exports.willBeActive = "Will be active after";
exports.accountDeletion = "Account deletion ";
exports.inputMailText = "Please input your current email to confirm the account deletion";
exports.cancelButton = "Cancel";
exports.deleteButton = "Delete";
exports.privacyPolicy = "Privacy Policy";
exports.updated = "Updated";
exports.apiEndPointGetPrivacy = "bx_block_terms_and_conditions/privacy_policies";
exports.termAndCodition = "Term And Condition";
exports.apiEndPointDeleteAccount = "account_block/accounts/:id?email=";
exports.deleteApiMethodType = "DELETE";
exports.changePass = "Change password";
exports.oldPass = "Old password";
exports.newPassLabel = "New password";
exports.passPlaceholder = "Enter New Password";
exports.confirmPassLabel = "Confirm new password";
exports.passConfirmPlaceholder = "Enter Confirm Password";
exports.setPassButton = "Set new password";
exports.passContain = "Your password must contain:";
exports.managedGoogle = "Managed by Google";
exports.newMailPlaceholder = "Enter New Email";
exports.apiEndPointGetVerifyCode = "bx_block_settings/get_confirmation";
exports.apiEndPointUpdateEmail = "account_block/accounts/change_email_address";
exports.pathTypeMethod = "PATCH";
exports.oldPassPlaceholder = "Enter Old Password";
exports.upperCase = "At least one capital letter";
exports.lowerCase = "At least one lowercase letter";
exports.numberValidate = "At least one number";
exports.chanracterLength = "Minimum character length is 8 characters";
exports.apiEndPointUpdatePass = "account_block/change_password"
// Customizable Area End