Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.getSubscriptionAPiEndPoint =
  "customisable_user_subscriptions/subscriptions";
exports.getSubscriptionAPiMethod = "GET";
exports.postSubscriptionAPiMethod = "POST";
exports.subscriptionApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customisableusersubscriptions";
exports.labelBodyText = "customisableusersubscriptions Body";

exports.btnExampleTitle = "CLICK ME";
exports.subscriptions = "Subscriptions";
exports.chooseSubscription = "Choose from the list of subscriptions";
exports.subscriptionDetailsScreenName = "SubscriptionDetails";
exports.goldCoinImageUrl =
  "https://akm-img-a-in.tosshub.com/sites/btmt/images/stories/goldcoin_660_102616075839.jpg";
exports.currencySymbol = "₹";
exports.zero = "0";
exports.subscribed = "Subscribed";
exports.subscribe = "Subscribe";
exports.subscriptionDetails = "Subscription Details";
exports.confirmSubscription = "Confirm Subscription";
exports.noSubscriptionAvailable = "No subscriptions available";
exports.plansEndPoint = "bx_block_custom_user_subs/subscriptions"
exports.subsEndPoint = "bx_block_custom_user_subs/user_subscriptions"
exports.stripeCheckoutSessionAPIEndpoint = "bx_block_stripe_integration/payments/create_checkout_session"
exports.userPaymentHistoryEndpoint = "/bx_block_custom_user_subs/user_subscriptions/history"
exports.mySubscriptionEndPoint = "/bx_block_custom_user_subs/my_subscription"
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
// Customizable Area End
